import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { apiAddPlaidLoanServicer, apiGetPlaidLinkToken } from 'api/loansAPI';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { PlaidLinkOnSuccess, PlaidLinkOptions, usePlaidLink } from 'react-plaid-link';
import { classNames } from 'utils/tailwindUtils';

interface ConnectAccountsProps {
  refreshAccountData: () => void;
  setOpenAdd529AccountModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenAddExternalBankAccountModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenAddManualLoanServicerModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConnectAccounts: React.FC<ConnectAccountsProps> = ({
  refreshAccountData,
  setOpenAdd529AccountModal,
  setOpenAddExternalBankAccountModal,
  setOpenAddManualLoanServicerModal,
}) => {
  const [plaidLinkToken, setPlaidLinkToken] = useState('');
  const [linkLoad, setLinkLoad] = useState(true);
  const [linkError, setLinkError] = useState('');

  const onSuccess = useCallback<PlaidLinkOnSuccess>(async (public_token, metadata) => {
    //update loans if added loans
    apiAddPlaidLoanServicer(public_token, metadata).then(
      (data) => {
        refreshAccountData();
        toast.success('New loan servicer added successfully!');
      },
      (error) => {
        toast.error(error.message);
      }
    );
  }, []);

  const config: PlaidLinkOptions = {
    token: plaidLinkToken,
    onSuccess,
    // onExit
    // onEvent
  };

  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    const generateToken = async () => {
      apiGetPlaidLinkToken().then(
        (data) => {
          setPlaidLinkToken(data.link_token);
          setLinkLoad(false);
        },
        (error) => {
          setLinkError(error.message);
          setLinkLoad(false);
        }
      );
    };
    generateToken();
  }, [setLinkLoad]);

  return (
    <Menu as="div" className="relative inline-block w-full text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Add account
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <a
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm cursor-pointer'
                  )}
                  onClick={() => {
                    setOpenAddManualLoanServicerModal(true);
                  }}
                >
                  Student loan
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm cursor-pointer'
                  )}
                  onClick={() => {
                    setOpenAdd529AccountModal(true);
                  }}
                >
                  529 savings
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm cursor-pointer'
                  )}
                  onClick={() => {
                    setOpenAddExternalBankAccountModal(true);
                  }}
                >
                  External bank account
                </a>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default ConnectAccounts;
