import { ExclamationIcon, InformationCircleIcon } from '@heroicons/react/solid';
import { apiGetContributionAccounts } from 'api/contributionsAPI';
import EmptyTablePlaceholder from 'common/EmptyTablePlaceholder';
import PageLoad from 'components/Load/PageLoad';
import { UserDataContext } from 'context/UserContext';
import { ExternalBankAccount } from 'interfaces/externalBankAccountInterfaces';
import { LoanServicer } from 'interfaces/loansInterfaces';
import { Savings529Account } from 'interfaces/savings529Interfaces';
import ConnectAccounts from 'participant/Accounts/ConnectAccounts';
import ExternalBankAccountElement from 'participant/Accounts/ExternalBankAccount/ExternalBankAccountElement';
import AddExternalBankAccountModal from 'participant/Accounts/ExternalBankAccount/modals/AddExternalBankAccountModal';
import LoanServicerElement from 'participant/Accounts/LoanServicer/LoanServicerElement';
import AddManualLoanServicerModal from 'participant/Accounts/LoanServicer/modals/AddManualLoanServicerModal';
import Add529AccountModal from 'participant/Accounts/Savings529Account/modals/Add529AccountModal';
import Savings529AccountElement from 'participant/Accounts/Savings529Account/Savings529AccountElement';
import React, { useContext, useEffect, useState } from 'react';

const AccountsPage: React.FC = () => {
  const { firebaseUser, currentOrganizationUser } = useContext(UserDataContext);
  const [loanServicers, setLoanServicers] = useState<LoanServicer[]>([]);
  const [savings529Accounts, setSavings529Accounts] = useState<Savings529Account[]>([]);
  const [externalBankAccounts, setExternalBankAccounts] = useState<ExternalBankAccount[]>([]);
  const [isLoadingLoanServicers, setIsLoadingLoanServicers] = useState(true);
  const [openAdd529AccountModal, setOpenAdd529AccountModal] = useState(false);
  const [openAddExternalBankAccountModal, setOpenAddExternalBankAccountModal] = useState(false);
  const [openAddManualLoanServicerModal, setOpenAddManualLoanServicerModal] = useState(false);

  const getContributionAccountsData = async () => {
    setIsLoadingLoanServicers(true);
    apiGetContributionAccounts().then(
      (data) => {
        setLoanServicers(data.loans);
        setSavings529Accounts(data.savings529Accounts);
        setExternalBankAccounts(data.externalBankAccounts);
        setIsLoadingLoanServicers(false);
      },
      (error) => {
        setIsLoadingLoanServicers(false);
      }
    );
  };

  useEffect(() => {
    if (firebaseUser) {
      getContributionAccountsData();
    }
  }, []);

  return (
    <>
      {isLoadingLoanServicers ? (
        <PageLoad />
      ) : (
        <div className="my-6 px-8 mx-auto max-w-7xl">
          <div>
            <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
              <h1 className="text-2xl font-bold text-gray-900">Connected Accounts</h1>
              <div className="w-full sm:w-auto sm:ml-auto">
                {currentOrganizationUser?.organization?.studentLoanRepaymentEnabled === true && (
                  <ConnectAccounts
                    refreshAccountData={getContributionAccountsData}
                    setOpenAdd529AccountModal={setOpenAdd529AccountModal}
                    setOpenAddExternalBankAccountModal={setOpenAddExternalBankAccountModal}
                    setOpenAddManualLoanServicerModal={setOpenAddManualLoanServicerModal}
                  />
                )}
              </div>
            </div>

            {currentOrganizationUser?.organization?.studentLoanRepaymentEnabled === false ? (
              <div className="mt-4">
                <EmptyTablePlaceholder
                  title={'Student loan and 529 contributions are not enabled for your organization'}
                  subtitle={
                    'Reach out to your organization administrator or Unsaddl support if you believe this is a mistake.'
                  }
                />
              </div>
            ) : (
              <>
                <div className="rounded-md bg-yellow-50 p-4 mt-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-yellow-800">Set your elections</h3>
                      <div className="mt-2 text-sm text-yellow-700">
                        <p>
                          Once you add your accounts, don't forget to set your contribution elections in the
                          Contribution Plans page. Failure to set elections will result in missed payments.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="rounded-md bg-yellow-50 p-4 mt-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-yellow-800">529 account verification</h3>
                      <div className="mt-2 text-sm text-yellow-700">
                        <p>
                          After adding a 529 account, please upload documents verifying your account and routing number
                          or payment address. Once your documents are uploaded, the Unsaddl team will verify your
                          accounts that are eligible to receive contributions.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="rounded-md bg-blue-50 p-4 mt-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-blue-800">Steps to add student loan</h3>
                      <div className="mt-2 text-sm text-blue-700">
                        <p>
                          Please complete the below steps to add your student loan. You will not be eligible for your
                          student loan benefit until all the steps are completed
                        </p>
                      </div>
                      <div className="mt-2 text-sm text-blue-700">
                        <p>
                          Add your student loan servicer (<span className="font-bold">Add account</span> {'>'}{' '}
                          <span className="font-bold">Student loan</span>)
                        </p>
                      </div>
                      <div className="mt-2 text-sm text-blue-700">
                        <p>
                          Add your student loan account under your student loan servicer (
                          <span className="font-bold">Manage servicer</span> {'>'}{' '}
                          <span className="font-bold">Add account</span>)
                        </p>
                      </div>
                      <div className="mt-2 text-sm text-blue-700">
                        <p>
                          Upload a statement verifying your account number and name (
                          <span className="font-bold">Manage servicer</span> {'>'}{' '}
                          <span className="font-bold">Manage statements</span>)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="space-y-4 mt-6">
                  {loanServicers.length === 0 &&
                    savings529Accounts.length === 0 &&
                    externalBankAccounts.length === 0 && (
                      <EmptyTablePlaceholder
                        title="No accounts"
                        subtitle="You have not connected any contribution accounts yet."
                      />
                    )}
                  {loanServicers.length > 0 && (
                    <div className="border-b border-gray-200 py-5">
                      <h3 className="text-lg font-medium leading-6 text-gray-900">Student loans</h3>
                    </div>
                  )}
                  {loanServicers.map((loanServicer) => (
                    <LoanServicerElement
                      loanServicer={loanServicer}
                      refreshAccountData={getContributionAccountsData}
                      key={loanServicer.id}
                    />
                  ))}
                  {savings529Accounts.length > 0 && (
                    <div className="border-b border-gray-200 py-5">
                      <h3 className="text-lg font-medium leading-6 text-gray-900">529 accounts</h3>
                    </div>
                  )}
                  {savings529Accounts.map((savings529Account) => (
                    <Savings529AccountElement
                      savings529Account={savings529Account}
                      refreshAccountData={getContributionAccountsData}
                      key={savings529Account.id}
                    />
                  ))}
                  {externalBankAccounts.length > 0 && (
                    <div className="border-b border-gray-200 py-5">
                      <h3 className="text-lg font-medium leading-6 text-gray-900">External bank accounts</h3>
                    </div>
                  )}
                  {externalBankAccounts.map((externalBankAccount) => (
                    <ExternalBankAccountElement
                      externalBankAccount={externalBankAccount}
                      refreshAccountData={getContributionAccountsData}
                      key={externalBankAccount.id}
                    />
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      )}
      <Add529AccountModal
        open={openAdd529AccountModal}
        setOpen={setOpenAdd529AccountModal}
        refreshAccountData={getContributionAccountsData}
      />
      <AddExternalBankAccountModal
        open={openAddExternalBankAccountModal}
        setOpen={setOpenAddExternalBankAccountModal}
        refreshAccountData={getContributionAccountsData}
      />
      <AddManualLoanServicerModal
        open={openAddManualLoanServicerModal}
        setOpen={setOpenAddManualLoanServicerModal}
        refreshAccountData={getContributionAccountsData}
      />
    </>
  );
};

export default AccountsPage;
