export const treasuryPrimeBillPayCounterpartyCheckBillAccountNumber = 'check';

export const treasuryPrimeMerchantSearchFields: {
  [merchantId: string]: { name: string; streetLine1?: string; city?: string; state?: string; postalCode: string };
} = {
  // Mohela - Laurel Road
  ins_116947: { name: 'MOHELA', postalCode: '63006' },
  // Mohela - SoFi
  ins_116464: { name: 'MOHELA', postalCode: '63006' },
  ins_116295: { name: 'FIRSTMARK', postalCode: '68103' },
  ins_116527: { name: 'FEDLOAN', postalCode: '63179' },
  ins_116528: { name: 'NELNET', postalCode: '97208' },
  ins_136573: { name: 'NELNET', postalCode: '97208' },
  // Mohela DOE
  ins_116866: { name: 'MOHELA', postalCode: '63179' },
  ins_116861: { name: 'US DEPART OF ED-GREAT LAKES', postalCode: '63179' },
  ins_116304: { name: 'EDFINANCIAL', postalCode: '97208' },
  ins_116945: { name: 'US DEPART OF ED-OSLA', postalCode: '97208' },
  // U-fi
  unsaddl_ins_190467: { name: 'FIRSTMARK', postalCode: '68103' },
  unsaddl_ins_671384: { name: 'FIRST TECH', postalCode: '97208' },
  // CommonBond Firstmark
  ins_116950: { name: 'FIRSTMARK', postalCode: '68103' },
  ins_116319: { name: 'AES STUDENT LOAN', postalCode: '21264' },
  unsaddl_ins_627309: {
    name: 'MOUNTAIN AMERICA CREDIT UNION',
    streetLine1: 'PO BOX 2331',
    city: 'SANDY',
    state: 'UT',
    postalCode: '84091',
  },
  unsaddl_ins_240665: {
    name: 'MOHELA',
    postalCode: '63006',
  },
  unsaddl_ins_053419: {
    name: 'AES STUDENT LOAN',
    postalCode: '21264',
  },
};

export const treasuryPrimeCheckCounterpartyData: {
  [merchantId: string]: {
    merchantName: string;
    streetLine1: string;
    streetLine2?: string;
    city: string;
    state: string;
    postalCode: string;
  };
} = {
  ins_132249: {
    merchantName: 'Aidvantage - DOE',
    streetLine1: 'PO BOX 4450',
    city: 'PORTLAND',
    state: 'OR',
    postalCode: '97208',
  },
  unsaddl_ins_636162: {
    merchantName: 'Aspire Servicing Center',
    streetLine1: 'PO BOX 659701',
    city: 'WEST DES MOINES',
    state: 'IA',
    postalCode: '50265',
  },
  unsaddl_ins_544880: {
    merchantName: 'Citizens',
    streetLine1: 'PO BOX 2595',
    city: 'OMAHA',
    state: 'NE',
    postalCode: '68103',
  },
  unsaddl_ins_420220: {
    merchantName: 'College Ave',
    streetLine1: 'PO BOX 5863',
    city: 'CAROL STREAM',
    state: 'IL',
    postalCode: '60197',
  },
  unsaddl_ins_714752: {
    merchantName: 'Discover Student Loans',
    streetLine1: 'PO BOX 71053',
    city: 'PHILADELPHIA',
    state: 'PA',
    postalCode: '19176',
  },
  ins_116949: {
    merchantName: 'Discover Student Loans',
    streetLine1: 'PO BOX 71053',
    city: 'PHILADELPHIA',
    state: 'PA',
    postalCode: '19176',
  },
  unsaddl_ins_416602: {
    merchantName: 'Earnest',
    streetLine1: 'PO BOX 9202',
    city: 'WILKES BARRE',
    state: 'PA',
    postalCode: '18773',
  },
  unsaddl_ins_277788: {
    merchantName: 'HESAA - NJCLASS',
    streetLine1: 'PO BOX 784033',
    city: 'PHILADELPHIA',
    state: 'PA',
    postalCode: '19178',
  },
  unsaddl_ins_831946: {
    merchantName: 'LendKey',
    streetLine1: 'PO BOX 824575',
    city: 'PHILADELPHIA',
    state: 'PA',
    postalCode: '19182',
  },
  ins_116248: {
    merchantName: 'NAVIENT',
    streetLine1: 'PO BOX 9533',
    city: 'WILKES BARRE',
    state: 'PA',
    postalCode: '18773',
  },
  unsaddl_ins_517682: {
    merchantName: 'NaviRefi',
    streetLine1: 'PO BOX 9115',
    city: 'WILKES BARRE',
    state: 'PA',
    postalCode: '18773',
  },
  ins_116528_d: {
    merchantName: 'Nelnet',
    streetLine1: 'PO BOX 2970',
    city: 'OMAHA',
    state: 'NE',
    postalCode: '68103',
  },
  ins_116528_j: {
    merchantName: 'Nelnet',
    streetLine1: 'PO BOX 2877',
    city: 'OMAHA',
    state: 'NE',
    postalCode: '68103',
  },
  unsaddl_ins_274736: {
    merchantName: 'PenFed',
    streetLine1: 'PO BOX 1400',
    city: 'ALEXANDRIA',
    state: 'VA',
    postalCode: '22313',
  },
  unsaddl_ins_274736_new: {
    merchantName: 'PenFed',
    streetLine1: 'PO BOX 247009',
    city: 'OMAHA',
    state: 'NE',
    postalCode: '68124',
  },
  ins_116944: {
    merchantName: 'Sallie Mae',
    streetLine1: 'PO BOX 8459',
    city: 'PHILADELPHIA',
    state: 'PA',
    postalCode: '19101',
  },
  unsaddl_ins_337573: {
    merchantName: 'US DEPARTMENT OF EDUCATION',
    streetLine1: 'NATIONAL PAYMENT CENTER',
    streetLine2: 'PO BOX 790336',
    city: 'ST LOUIS',
    state: 'MO',
    postalCode: '63179',
  },
  unsaddl_ins_27169: {
    merchantName: 'First Republic Bank',
    streetLine1: '111 PINE ST',
    city: 'SAN FRANCISCO',
    state: 'CA',
    postalCode: '94111',
  },
  // EdFinancial - new as of 04/2023
  ins_116304_new_2023: {
    merchantName: 'EdFinancial Services',
    streetLine1: 'PO BOX 790322',
    city: 'ST LOUIS',
    state: 'MO',
    postalCode: '63179',
  },

  unsaddl_ins_114371: {
    merchantName: 'Heartland ECSI',
    streetLine1: 'PO BOX 1287',
    city: 'MOON TOWNSHIP',
    state: 'PA',
    postalCode: '15108',
  },
  unsaddl_ins_764960: {
    merchantName: 'Unisa',
    streetLine1: 'PO BOX 4385',
    city: 'ENGLEWOOD',
    state: 'CO',
    postalCode: '80155',
  },
  unsaddl_ins_465020: {
    merchantName: 'Minnesota College Savings Plan',
    streetLine1: 'PO BOX 219455',
    city: 'KANSAS CITY',
    state: 'MO',
    postalCode: '64121',
  },
  unsaddl_ins_149358: {
    merchantName: 'Devin Childers',
    streetLine1: '101 EAST KENNEDY BLVD',
    streetLine2: 'SUITE 1000',
    city: 'TAMPA',
    state: 'FL',
    postalCode: '33602',
  },
  unsaddl_ins_829164: {
    merchantName: 'Edvest 529 College Savings Plan',
    streetLine1: 'PO BOX 219437',
    city: 'KANSAS CITY',
    state: 'MO',
    postalCode: '64121',
  },
  unsaddl_ins_727103: {
    merchantName: 'Stable Value Plus College Savings Plan CollegeInvest',
    streetLine1: '1600 BROADWAY',
    streetLine2: 'SUITE 2300',
    city: 'DENVER',
    state: 'CO',
    postalCode: '80202',
  },
  unsaddl_ins_214862: {
    merchantName: 'BlackRock 529',
    streetLine1: 'PO BOX 534433',
    city: 'PITTSBURGH',
    state: 'PA',
    postalCode: '15253',
  },
  unsaddl_ins_400707: {
    merchantName: 'T Rowe Price',
    streetLine1: 'PO BOX 17300',
    city: 'BALTIMORE',
    state: 'MD',
    postalCode: '21297',
  },
  unsaddl_ins_578296: {
    merchantName: 'PA 529 Guaranteed Savings Plan',
    streetLine1: 'PO BOX 55463',
    city: 'BOSTON',
    state: 'MA',
    postalCode: '02205',
  },
  unsaddl_ins_212582: {
    merchantName: 'PA 529 College and Career Savings Program',
    streetLine1: 'PO BOX 55378',
    city: 'BOSTON',
    state: 'MA',
    postalCode: '02205',
  },
  unsaddl_ins_916578: {
    merchantName: 'Path2College 529 Plan',
    streetLine1: 'PO BOX 219293',
    city: 'KANSAS CITY',
    state: 'MO',
    postalCode: '64121',
  },
  unsaddl_ins_399566: {
    merchantName: 'CollegeChoice 529 Direct Savings Plan',
    streetLine1: 'PO BOX 219418',
    city: 'KANSAS CITY',
    state: 'MO',
    postalCode: '64121',
  },
  unsaddl_ins_671264: {
    merchantName: 'The Vanguard 529 College Savings Plan',
    streetLine1: 'PO BOX 55111',
    city: 'BOSTON',
    state: 'MA',
    postalCode: '02205',
  },
  unsaddl_ins_700009: {
    merchantName: 'NEST 529 Advisor Plan',
    streetLine1: 'PO BOX 84529',
    city: 'LINCOLN',
    state: 'NE',
    postalCode: '68501',
  },
  unsaddl_ins_990229: {
    merchantName: 'Bright Start Direct 529',
    streetLine1: 'PO BOX 85298',
    city: 'LINCOLN',
    state: 'NE',
    postalCode: '68501',
  },
  unsaddl_ins_723277: {
    merchantName: 'Wright-Patt Credit Union',
    streetLine1: 'PO BOX 5879',
    city: 'CAROL STREAM',
    state: 'IL',
    postalCode: '60197',
  },
  ins_136433: {
    merchantName: 'Mohela',
    streetLine1: 'PO BOX 790453',
    city: 'ST LOUIS',
    state: 'MO',
    postalCode: '63179',
  },
  unsaddl_ins_042903: {
    merchantName: 'John Hancock Freedom 529',
    streetLine1: 'PO BOX 17603',
    city: 'BALTIMORE',
    state: 'MD',
    postalCode: '21297',
  },
  unsaddl_ins_762971: {
    merchantName: 'Eric Popp',
    streetLine1: '17305 CEDAR AVENUE SOUTH',
    streetLine2: 'SUITE 250',
    city: 'LAKEVILLE',
    state: 'MN',
    postalCode: '55044',
  },
  unsaddl_ins_455119: {
    merchantName: 'USAA College Savings Plan',
    streetLine1: 'PO BOX 55354',
    city: 'BOSTON',
    state: 'MA',
    postalCode: '02205',
  },
  unsaddl_ins_774093: {
    merchantName: 'CollegeAmerica',
    streetLine1: 'PO BOX 6273',
    city: 'INDIANAPOLIS',
    state: 'IN',
    postalCode: '46206',
  },
  unsaddl_ins_20614: {
    merchantName: 'US DEPARTMENT OF EDUCATION',
    streetLine1: 'PO BOX 790123',
    city: 'ST LOUIS',
    state: 'MO',
    postalCode: '63179',
  },
  unsaddl_ins_255053: {
    merchantName: 'MOHELA',
    streetLine1: 'PO BOX 1022',
    city: 'CHESTERFIELD',
    state: 'MO',
    postalCode: '63006',
  },
  unsaddl_ins_303024: {
    merchantName: 'RISLA',
    streetLine1: 'PO BOX 150501',
    city: 'HARTFORD',
    state: 'CT',
    postalCode: '06115',
  },
  unsaddl_ins_622538: {
    merchantName: 'SoFi',
    streetLine1: 'PO BOX 2595',
    city: 'OMAHA',
    state: 'NE',
    postalCode: '68103',
  },
  unsaddl_ins_885829: {
    merchantName: 'Mohela',
    streetLine1: 'PO BOX 9533',
    city: 'WILKES BARRE',
    state: 'PA',
    postalCode: '18773',
  },
  unsaddl_ins_164045: {
    merchantName: 'Mohela',
    streetLine1: 'PO BOX 9000',
    city: 'WILKES BARRE',
    state: 'PA',
    postalCode: '18773',
  },
};
